import React, { useState, useEffect, useRef } from "react";
import { TweenMax, Power4 } from "gsap";
import "./ShowCard.css";
import Bel2 from "../../assets/belcolor__2.jpg";
import Bel3 from "../../assets/belcolor__3.jpg";
import Bel4 from "../../assets/belcolor__4.jpg";
import Bel5 from "../../assets/belcolor__5.jpg";
import Bel6 from "../../assets/belcolor__6.jpg";
import Bel7 from "../../assets/belcolor__7.jpg";
import Bel8 from "../../assets/belcolor__8.jpg";
import Bel9 from "../../assets/belcolor__9.jpg";
import Bel10 from "../../assets/belcolor__10.jpg";
import Bel11 from "../../assets/belcolor__11.jpg";
import Bel12 from "../../assets/belcolor__12.jpg";
import Bel13 from "../../assets/belcolor__13.jpg";
import Bel14 from "../../assets/belcolor__14.jpg";
import Close from "@material-ui/icons/Close";
import { Play } from "../Playing/Play";
import { Hidden } from "@material-ui/core";

function numberWithSpaces(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}


let choiceSrc;

let changeChoice = () => {
  let rand = Math.floor(Math.random() * 13) + 2;

  if (rand == 2) {
    choiceSrc = Bel2;
  } else if (rand == 3) {
    choiceSrc = Bel3;
  } else if (rand == 4) {
    choiceSrc = Bel4;
  } else if (rand == 5) {
    choiceSrc = Bel5;
  } else if (rand == 6) {
    choiceSrc = Bel6;
  } else if (rand == 7) {
    choiceSrc = Bel7;
  } else if (rand == 8) {
    choiceSrc = Bel8;
  } else if (rand == 9) {
    choiceSrc = Bel9;
  } else if (rand == 10) {
    choiceSrc = Bel10;
  } else if (rand == 11) {
    choiceSrc = Bel11;
  } else if (rand == 12) {
    choiceSrc = Bel12;
  } else if (rand == 13) {
    choiceSrc = Bel13;
  } else if (rand == 14) {
    choiceSrc = Bel14;
  }
};
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

changeChoice();

export default ({ getTheData, image, title, calc, closeAction, nextTop }) => {
  const [top10, setTop] = useState([
    ["0", ""],
    ["0", ""],
    ["0", ""],
    ["0", ""],
    ["0", ""],
    ["0", ""],
    ["0", ""],
    ["0", ""],
    ["0", ""],
    ["0", ""],
  ]);
  const tablee = useRef(null);
  const tableee = useRef(null);
  const pic = useRef(null);
  const container = useRef(null);

  const [isPlaying,setPlaying] = useState(false);
  const [madeChoice,setChoice] = useState(null);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  let premier = top10[0][0].replace(",", ".");
  premier = premier.replace(",", ".");


  useEffect(() => {
    getTheData(setTop);
    changeChoice();
  }, [getTheData]);

  useEffect(() => {
    getTheData(setTop);
  }, [isPlaying]);

  useEffect(() => {
    if (top10[0][0] !== "0") {
      TweenMax.to(tablee.current, 2, {
        x: 0,
        opacity: 1,
        ease: Power4.easeInOut,
      });
      TweenMax.fromTo(
        tableee.current,
        2,
        { opacity: 0, x: "-30px" },
        {
          x: 0,
          opacity: 1,
          ease: Power4.easeInOut,
        }
      );
      TweenMax.fromTo(
        pic.current,
        2,
        { opacity: 0, x: 50 },
        {
          x: 0,
          opacity: 1,
          delay: 0.7,
          ease: Power4.easeInOut,
        }
      );
    }
  },[top10]);


  let open = () => {
    setPlaying(true);    
  }

  let fermer = () => {
    setPlaying(false);
    setChoice(true);
  }

  let classement = 1;

  return (
    <div>
      <div> 
    {isPlaying ? <Play close={fermer} ref={container} forceOne={title}/> : 
    <div className="show" style={{overflow:"hidden"}}>
      <div className="show_close">
        <Close onClick={closeAction} fontSize="large" alt="Fermer" />
      </div>
      <div className="show_top" ref={tableee}>
        <img className="show_logo" alt="Logo" src={image} />
        <p className="show_title">
          {title} {" " + calc}{" "}
        </p>
      </div>
      <div className="show_middle">
        
        <div className="show_ranking" ref={tablee}>
          {top10.map((e) => {

            let min = 30;
            
            let res = e[1];
            let tempo = e[0];
            let res2 = numberWithSpaces(tempo);

            
            if(windowDimensions.width < 600) {
              min = 80;
              if(res.length > 22) {
                res = res.substring(0,22);
              }
            }

            let finalWidth =
              classement == 1
                ? "100%"
                : Math.floor((e[0].replace(",", ".") * (100-min)) / premier) +
                min +
                "%";
            

            return (
              <div className="show_line" key={classement}>
                <p className={"show_rank nb" + classement}> {classement++} </p>
                <div
                  className={"show_name line" + classement}
                  style={{
                    width: "" + finalWidth,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <p
                    style={{
                      height: 40,
                      display: "inline-block",
                      verticalAlign: "top",
                      margin: 0,
                      overflow:"hidden",
                    }}
                  >
                    {(madeChoice || windowDimensions.width < 800 )&& res + "" }
                  </p>{" "}
                  <p
                    style={{
                      margin: 0,
                      width:"fit-content",
                      verticalAlign: "top",
                      display: "inline-block",
                      textAlign: "right",
                      height: 40,
                      paddingRight: 20,
                    }}
                  >
                    {" "}
                    {res2}{" "}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <img ref={pic} className="show_image" alt="Img" src={choiceSrc} />
      </div>
      <div className="show_choices">
        <div className="show_next show_play" onClick={open}>
          {" "}
        Play it{" "}
        </div>
        <div className="show_next" onClick={nextTop}>
          {" "}
        Next Top10{" "}
        </div>
      </div>
    </div>
    }
    </div>
    {madeChoice || windowDimensions.width < 800 ? "" :
    <div className="decide">
      <div className="decide_left">
        <p className="decide_rank" onClick={()=>setChoice(true)}> Look at the ranking </p> 
      </div>
      <div className="decide_right">

        <p className="decide_rank" onClick={()=>{setPlaying(true); setChoice(true)}}>Try to guess ! </p> 
      </div>
    </div>
    }
    </div>
  );
};


import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAsuNTdTbqNYLmvhdsj_hpHsWx5d727YOo",
    authDomain: "top10-app-b6f48.firebaseapp.com",
    databaseURL: "https://top10-app-b6f48.firebaseio.com",
    projectId: "top10-app-b6f48",
    storageBucket: "top10-app-b6f48.appspot.com",
    messagingSenderId: "221999278407",
    appId: "1:221999278407:web:36b3e5653d155edb290462"
});


const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

let chargeFb = (setter,premierRes,want) => {
    let res1 = premierRes.docs.map((doc) => doc.data());
        let doIt = false;
  
        if (res1.length > 9) {
          let resFinal = [];
          for (let result of res1) {
            if (result.lastUpdate) {
              let prec = result.lastUpdate;
              let act = Date.now();
  
              if (prec + 604800 < act && want) {
                doIt = true;
                break;
              }
            } else {
              resFinal[result.rank - 1] = [result.qtt, result.name];
            }
          }
          if (!doIt) {
            setter(resFinal);
          }
        } else {
          doIt = true;
        }
  
        return doIt;
  }

export {db, auth, storage,chargeFb};


import React from "react";
import { store } from "./store";
import "./Info.css";
import Close from "@material-ui/icons/Close";

export default ({closeIt}) => {
  return (
    <div className="info">
        <div className="info_close">
            <Close
              fontSize="large"
              onClick={closeIt}
              alt="Fermer"
            />
          </div>
      <h3 className="info_title">
        Website made by <a href="https://eliottgandiolle.fr"> Eliott Gandiolle </a>
      </h3>
      <div className="info_duo">
          <p className="info_nom" style={{fontWeight:"bold"}}>  Top10 name </p>
          <p style={{fontWeight:"bold"}}> Icon author   </p>
        </div>
      {store.map((e) => (
        <div className="info_duo">
          <p className="info_nom"> {e.title} &ensp; </p>
          <a href={e.link} > Link  </a>
        </div>
      ))}
    </div>
  );
}

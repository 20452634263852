import React, { useState, useEffect, useRef } from "react";
import { InputBase } from "@material-ui/core";
import "./Explorer.css";
import SearchIcon from "@material-ui/icons/Search";
import Card from "./Card";
import { store } from "../../store";
import ShowCard from "./ShowCard";
import { TweenMax, Power4 } from "gsap";
import Close from "@material-ui/icons/Close";


function shuffle(array) {
  array.sort(() => Math.random() - 0.5);
}
shuffle(store);

export default ({ close }) => {
  const [terms, setTerms] = useState("");
  const [showSomething, setShow] = useState(null);
  const refer = useRef(null);

  let setChange = (e) => {
    setTerms(e.target.value);
  };

  let goIn = (titre) => {
    let elem = store.find((e) => e.title === titre);
    TweenMax.to(refer.current, 1.5, {
      overflow: "hidden",
      opacity: 0,
      ease: Power4.easeInOut,
    });
    TweenMax.to(refer.current, 0.1, {
      overflow: "hidden",
      display:"none",
      ease: Power4.easeInOut,
      delay: 1.5,
    });

    setTimeout(
      () =>
        setShow({
          gets: elem.getTheData,
          img: elem.link,
          cal: elem.typeOfRank,
          titre: elem.title,
        }),
      1200
    );
  };

  let onClose = () => {


    TweenMax.to(refer.current, 0.1, {
      overflow: "hidden",
      display: "flex",
      ease: Power4.easeInOut,
    });
    setShow(null)

    TweenMax.to(refer.current, 1.5, {
      overflow: "hidden",
      opacity: 1,
      delay: 0.1,
      ease: Power4.easeInOut,
    });

  };

  let nextTop10 = () => {
    let actualName = showSomething.titre;
    let rand = Math.floor(Math.random() * store.length);
    while(store[rand].title == actualName) {
      rand = Math.floor(Math.random() * store.length);
    }

    let elem = store[rand];
    setShow({
      gets: elem.getTheData,
      img: elem.link,
      cal: elem.typeOfRank,
      titre: elem.title,
    });

  }

  return (
    <div style={{height:"100vh"}}>
      {showSomething && (
        <ShowCard
          title={showSomething.titre}
          image={showSomething.img}
          getTheData={showSomething.gets}
          calc={showSomething.cal}
          nextTop={nextTop10}
          closeAction={onClose}
        />
      )}
      <div className="explorer" ref={refer}>
        {!showSomething && (
          <div className="explorer_close">
            <Close
              fontSize="large"
              onClick={close}
              alt="Fermer"
            />
          </div>
        )}
        <div className="explorer_recherche">
          <InputBase
            onChange={setChange}
            className="explorer_input"
            placeholder="Search a special top10"
          />
          <SearchIcon className="explorer_icon" />
        </div>
        <div className="explorer_list">
          {store.map((e) => {
            if (e.title.toLowerCase().includes(terms.toLowerCase()))
              return <Card key={e.title} image={e.link} select={goIn} titre={e.title} />;
          })}
        </div>
      </div>
    </div>
  );
};

import React from 'react'
import "./Card.css"

export default ({image,titre,select}) => {
    return (
        <div className="card">
            <img className="card_logo" src={image}/>
            <p className="card_title"> {titre} </p>
            <div className="card_select" onClick={()=>select(titre)}> Check </div>
        </div>
    )
}

import React, {useRef,useState,useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Background from "./assets/belcolor__13.jpg"
import { TweenMax , Power4} from "gsap";
import {Play} from "./components/Playing/Play"
import Explorer from "./components/Exploring/Explorer"
import InformationPage from "./InformationPage"


let rand = Math.random();

function App() {

  const refer = useRef(null);

  const [isCredits,setCredits] = useState(false);
  const [isOpen,setOpen] = useState(true);
  const [isGame,setGame] = useState(false);
  const [isExploring,setExploring] = useState(false);
  const [styleFlow,setStyle] = useState("hidden");
  const [Compo,setCompo] = useState(null);



  const referT = useRef(null);
  const referB = useRef(null);
  const referS = useRef(null);
  const referI = useRef(null);



  useEffect(() => {
    TweenMax.fromTo(referT.current, 1.5,{x:-100,opacity:0} ,{x:0,opacity:1,ease:Power4.easeInOut});
    TweenMax.fromTo(referS.current, 1.5,{x:-100,opacity:0}, {x:0,opacity:1,delay:0.8,ease:Power4.easeInOut});
    TweenMax.fromTo(referB.current, 1.5,{x:-100,opacity:0}, {x:0,opacity:1,delay:0.8,ease:Power4.easeInOut});
    TweenMax.fromTo(referI.current, 3,{opacity:0}, {opacity:1,delay:1.2,ease:Power4.easeInOut});

  }, [])
  

  let onClose = () => {
    setStyle("hidden")
    TweenMax.to(refer.current, 1.5, {overflow:"visible",x:"0",display:"flex",ease:Power4.easeInOut});
    setTimeout(()=>    {setGame(false)   ;setOpen(true);}
    , 1500);
  }

  let onClose2 = () => {
    setStyle("hidden")

    TweenMax.to(refer.current, 1.5, {overflow:"visible",y:"0",display:"flex",ease:Power4.easeInOut});
    setTimeout(()=>    {setExploring(false)   ;setOpen(true);}
    , 1500);
  }

  let onClose3 = () => {
    setStyle("hidden")

    TweenMax.to(refer.current, 1.5, {overflow:"visible",y:"0",display:"flex",ease:Power4.easeInOut});
    setTimeout(()=>    {setCredits(false)   ;setOpen(true);}
    , 1500);
  }

  let startInformation = () => {
    TweenMax.to(refer.current, 1.5, {overflow:"visible",y:"-100%",display:"none",ease:Power4.easeInOut});
    setOpen(false);
    setCredits(true)
    setTimeout(()=> setStyle("visible"),1500)
  }

  let startGame = () => {
    TweenMax.to(refer.current, 1.5, {overflow:"visible",x:"100%",display:"none",ease:Power4.easeInOut});
    setOpen(false);
    setGame(true)
    setTimeout(()=> setStyle("visible"),1500)
  }

  let startExploring = () => {
    TweenMax.to(refer.current, 1.5, {overflow:"visible",y:"-100%",display:"none",ease:Power4.easeInOut});
    setOpen(false);
    setExploring(true)
    setTimeout(()=> setStyle("visible"),1500)

  }



  return (
    <div className="app" style={{overflow:styleFlow}}>
      <div className="app_accueil" ref={refer}>
        <div className="app_left" >
          <h1 ref={referT}> Top 10 </h1>
          <h2 ref={referS}> Check out some live ranking about all subjects in the world  </h2>
          <div ref={referB} className="app_choice">
            <button onClick={startGame} className="app_play app_buttons"> Play </button>
            <button onClick={startExploring} className="app_explore app_buttons"> Explore </button>

          </div>
          <h2 className="add"> ("Play" mode isn't available on mobile)  </h2>

        </div>
        <img ref={referI} className="app_picture" alt="HomePAge" src={Background} />
        <p className="app_credits" onClick={startInformation}>
          Credits
        </p>
        </div>
        {isGame && <Play close={onClose} />}
        {isExploring && <Explorer close={onClose2} />}
        {isCredits && <InformationPage closeIt={onClose3}/>}
        
    </div>
  );
}

export default App;

import React, { useState, useEffect, useRef } from "react";
import { TweenMax, Power4 } from "gsap";
import Draggable from "react-draggable";
import CountUp from "react-countup";
import "./Shaking.css";
import Close from "@material-ui/icons/Close";
import Refresh from "@material-ui/icons/Sync";
import { store } from "../../store";
import "./Play2.css";
import "./Play.css";

function numberWithSpaces(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

function shuffle(array) {
  array.sort(() => Math.random() - 0.5);
}

export const Play = ({ close, forceOne }) => {
  const [top10, setTop] = useState([
    ["0", ""],
    ["0", ""],
    ["0", ""],
    ["0", ""],
    ["0", ""],
    ["0", ""],
    ["0", ""],
    ["0", ""],
    ["0", ""],
    ["0", ""],
  ]);

  const [cssN, setCss] = useState("playB");
  const [lastOne, setLast] = useState("");

  const [responses, setResponses] = useState([{ nom: "", id: -1 }]);
  const [score, setScore] = useState(0);
  const [previousScore, setPreviousScore] = useState(0);
  const [isEnd, setEnd] = useState(false);
  const [index, setIndex] = useState(0);

  const tablee = useRef(null);
  const [onTheRank, setOnTheRank] = useState(-1);
  const [showResults, setShowResults] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  let premier = top10[0][0].replace(",", ".");
  premier = premier.replace(",", ".");
  const [controlledPosition, setControlledPosition] = useState({ x: 0, y: 0 });

  let reset = (actual) => {

      let disable = document.querySelector("."+cssN + "_close");
      console.log(disable)
      disable.classList.add("disable");


      let alea = Math.floor(Math.random() * store.length);
      if(forceOne && lastOne === "") {
        alea = store.findIndex((e) => forceOne === e.title );
        setLast(store[alea].nom);
      } else {
        while (store[alea].nom == lastOne) {
          alea = Math.floor(Math.random() * store.length);
        }
        setLast(actual);

      }
      
      

      store[alea].getTheData(setTop).then(() =>       disable.classList.remove("disable")
      );
      setOnTheRank(-1);
      setIndex(alea);
      setEnd(false);
      setScore(0);
      setResponses([{ nom: "", id: -1 }]);
      setShowResults([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ]);
      for (let element of document.querySelectorAll("." + cssN + "_success")) {
          element.classList.remove(cssN + "_success");
      }

      

      let nouvelAlea = Math.random();
      if (nouvelAlea > 0.5) {
        setCss("playB");
      } else {
        setCss("playR");
      }


    
  };

  useEffect(() => {
    reset("");
  }, []);

  let onControlledDrag = (e, position) => {
    const { x, y } = position;
    setControlledPosition({ x: x, y: y });
  };

  let onControlledDragStop = (e, position) => {
    let elements = document.elementsFromPoint(e.clientX, e.clientY);
    let on = false;
    let elemFin;
    for (let element of elements) {
      if (element.classList.contains(cssN + "_name")) {
        on = true;
        elemFin = element;
      }
    }

    
    for (let elem of responses) {
      let text = e.target.innerText + "";
      setPreviousScore(score);
      
      if (elem.nom.replace(/\W/g, '') == text.replace(/\W/g, '')) {
        if (elem.id == onTheRank && on) {
          let results = showResults;
          results[onTheRank - 1] = true;

          setShowResults(results);
          e.target.click();
          e.target.style = "display:none";
          elemFin.classList.add(cssN + "_success");
          let elem = document.querySelector("." + cssN + "_score");
          elem.classList.add(cssN + "_score_success");
          setScore(score + 1000);
          setTimeout(() => {
            elem.classList.remove(cssN + "_score_success");
          }, 1800);
          let finn = true;
          for (let res of showResults) {
            if (!res) {
              finn = false;
              break;
            }
          }
          setEnd(finn);
        } else {
          if (on) {
            setScore(score - 500);
            let elem = document.querySelector("." + cssN + "_score");
            elem.classList.add(cssN + "_score_wrong");
            elemFin.classList.add("shake-horizontal");
            elemFin.classList.add(cssN + "_wrong");
            setTimeout(() => {
              elemFin.classList.remove("shake-horizontal");
              elemFin.classList.remove(cssN + "_wrong");
            }, 500);
            setTimeout(() => {
              elem.classList.remove(cssN + "_score_wrong");
            }, 1800);
          }
        }
      }
      setControlledPosition({ x: 0, y: 0 });
    }

    for (let element of document.querySelectorAll("." + cssN + "_name")) {
      if (element.classList.contains(cssN + "_hover")) {
        element.classList.remove(cssN + "_hover");
        break;
      }
    }
  };

  useEffect(() => {
    if (top10[0][0] !== "0") {
      TweenMax.to(tablee.current, 2, {
        x: 0,
        opacity: 1,
        ease: Power4.easeInOut,
      });
      if (responses[0].nom === "") {
        let i = 1;
        let reg = [];
        for (let res of top10) {
          reg.push({ nom: res[1], id: i });
          i++;
        }
        shuffle(reg);
        setResponses(reg);
      }
    }
  }, [top10]);

  let checkElems = (e) => {
    let elements = document.elementsFromPoint(e.clientX, e.clientY);
    for (let element of document.querySelectorAll("." + cssN + "_name")) {
      if (element.classList.contains(cssN + "_hover")) {
        if (!elements.includes(element)) {
          element.classList.remove(cssN + "_hover");
        }
        break;
      }
    }
    for (let element of elements) {
      if (element.classList.contains(cssN + "_name")) {
        let classs = element.className;
        let result =
          classs.substring(
            classs.indexOf("line") + 4,
            classs.indexOf("line") + 6
          ) - 1;

        setOnTheRank(result);
        if (
          !element.classList.contains(cssN + "_hover") &&
          !showResults[result - 1]
        ) {
          element.classList.add(cssN + "_hover");
        }
      }
    }
  };

  let classement = 1;

  return (
    <div className="play">
      <div className={cssN + "_close"}>
        {" "}
        <Close
          className={cssN + "_cross"}
          fontSize="large"
          onClick={close}
          alt="Fermer"
        />{" "}
        <Refresh
          style={{ fontSize: 30 }}
          onClick={() => reset(store[index].nom)}
          className={cssN + "_refresh"}
          alt="Refresh"
        />{" "}
      </div>

      <div className={cssN + "_left"}>
        <h1 className={cssN + "_title"}>
          {" "}
          {store[index].nom} &ensp;{" "}
          <p style={{ margin: 0, fontSize: "1.5vw", lineHeight: 3 }}>
            {" "}
            {store[index].typeOfRank}{" "}
          </p>{" "}
        </h1>
        <div className={cssN + "_ranking"} ref={tablee}>
          {top10.map((e) => {
            let finalWidth =
              classement == 1
                ? "100%"
                : Math.floor((e[0].replace(",", ".") * 70) / premier) +
                  30 +
                  "%";
            let res = showResults[classement - 1] ? e[1] : "";
            let tempo = showResults[classement - 1] ? e[0] : "";
            let res2 = numberWithSpaces(tempo);
            return (
              <div className={cssN + "_line"} key={classement}>
                <p className={cssN + "_rank nb" + classement}>
                  {" "}
                  {classement++}{" "}
                </p>
                <div
                  className={cssN + "_name line" + classement}
                  style={{
                    width: "" + finalWidth,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <p
                    style={{
                      height: 40,
                      display: "inline-block",
                      verticalAlign: "top",
                      margin: 0,
                    }}
                  >
                    {res}
                  </p>{" "}
                  <p
                    style={{
                      margin: 0,
                      verticalAlign: "top",
                      display: "inline-block",
                      textAlign: "right",
                      height: 40,
                      paddingRight: 20,
                    }}
                  >
                    {" "}
                    {res2}{" "}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div className={cssN + "_finished"}>
          {isEnd && (
            <p
              onClick={() => reset(store[index].nom)}
              className={cssN + "_next"}
            >
              {" "}
              Passer au suivant{" "}
            </p>
          )}
        </div>
      </div>
      <div className={cssN + "_right"}>
        <div>
          <h1 className={cssN + "_rules"} style={{ marginBottom: 0 }}>
            {" "}
            Try to match each name with its rank{" "}
          </h1>
          <h1
            className={cssN + "_rules"}
            style={{ marginTop: 10, paddingTop: 0 }}
          >
            (drag and drop)
          </h1>
        </div>
        <div className={cssN + "_responses"}>
          {responses.map((e) => (
            <Draggable
              key={e.nom}
              onDrag={checkElems}
              position={controlledPosition}
              onStop={onControlledDragStop}
            >
              <div className={cssN + "_draggable"}> {e.nom} </div>
            </Draggable>
          ))}
        </div>
        <h1 className={cssN + "_score"}>
          {" "}
          <CountUp start={previousScore} end={score} /> pts{" "}
        </h1>
      </div>
    </div>
  );
};
